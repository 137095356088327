import React from "react"
import aboutUs from "../../assets/images/new-imgs/about-us-2.png"
import eneftiLogo from "../../assets/images/EneftiHosting/logo-500px.png";


const PromoInfoRowEnefti = () => {
    return (
        <section>
            <section id="promo-info-row">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 info-text">
                            <img className='enefti-logo' src={eneftiLogo} alt="Enefti Logo" loading="lazy"/>
                            <h2 className="h3-title mb-3">Intuitive Enefti Hosting</h2>
                            <p>Your presentation website or WooCommerce online shop are complete only if they use a performant
                                Enefti Hosting. Be prepared to scale up your online presence with the help of the Enefti hosting
                                packages offered by SiteBunker.</p>

                            <h2 className="h3-title mb-3 mt-5">Security assured by Imunify360</h2>
                            <p>Apart from the premium storage, RAM and the advanced processing resources needed for your Enefti
                                hosting, with SiteBunker you are also protected from any attack. We ensure the best security by having
                                implemented the Arbor solution. Contact us for more details.</p>
                        </div>
                        <div className="col-md-6 position-relative">
                            <div className="svg-img">
                                <img src={aboutUs} loading="lazy" alt="Efficient Enefti hosting" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="packages-intro">
                <div className="packages-area pb-2 hosting-plans-intro container-fluid pt-100">
                    <div className="container">
                        <div className="section-title section-title-800">
                            <h2 className="h3-title mb-3 text-white">Intuitive WooCommerce Hosting</h2>
                            <p className="text-white">Easy to use by both enthusiasts and programmers, the Enefti hosting
                                subscriptions can be activated as soon as you place the online order.</p>
                            <p className="text-white">You will receive the account
                                activation email within a few minutes after registering the online payment.</p>
                        </div>
                    </div>
                </div>
            </section>
        </section>

    )
}

export default PromoInfoRowEnefti
