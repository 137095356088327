import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"
import FreeHosting from "../components/Common/FreeHosting";
import PromoInfoRowEnefti from "../components/PromoInfoRow/PromoInfoRowEnefti";
import CustomerSupportEnefti from "../components/Index/CustomerSupportEnefti";
import SiteBenefitsEnefti from "../components/SiteBenefits/SiteBenefitsEnefti";
import IntroHostingPlansEnefti from "../components/HostingPlans/IntroHostingPlansEnefti";
import EneftiBannerArea from "../components/EneftiHosting/EneftiBannerArea";

const EneftiSsdHosting = () => {
    return (
        <Layout>
            <SEO title="Efficient Enefti hosting for your website  - Sitebunker.net"
                 description="Are you looking for Enefti hosting for your site? Discover Sitebunker.net web hosting services, designed for any budget. Free SSL certificate and Anti DDoS protection included"
            />
            <Navbar/>
            <EneftiBannerArea pageTitle="Advanced Enefti Hosting - LiteSpeed WP Cache guaranteed"
                              pageDescription="Recommended for presentation, news, blog websites and WooCommerce online shops, the Enefti hosting subscriptions available at SiteBunker include multiple benefits such as  premium resources for processing, storage, RAM and FREE 30 DAYS TRIAL."
            />
            <IntroHostingPlansEnefti/>
            <HostingPlanBlock category="WordPress EN"/>
            <FreeHosting title='Choose your hosting package and get a free .com domain'
                         text1='Enjoy premium services for your Enefti site. For any chosen hosting package you have a free .com domain for one year. The offer applies to all of the above packages, except the Start package.'
                         text2='With SiteBunker.net, you have complete qualitative services and packages adapted to any website’s needs.'/>
            <PromoInfoRowEnefti/>
            <InfoBlocks/>
            <ClientFeedback
                title="Find out more about why our customers recommend "
                bold="SiteBunker Enefti hosting services!"
            />
            <SiteBenefitsEnefti/>
            <CustomerSupportEnefti/>
            <Footer/>
        </Layout>
    )
}

export default EneftiSsdHosting
