import React, {Component} from "react"
import "../../assets/css/EneftiHosting/EneftiBannerArea.css"
import wordpressImg from "../../assets/images/new-imgs/wordpress-big.png"
import eneftiLogo from "../../assets/images/EneftiHosting/logo-500px.png"


class EneftiBannerArea extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {pageTitle, pageDescription} = this.props

        return (
            <div id="bannerArea" className="banner-title-area">
                <div className="background-holder">
                    <div className="container">
                        <div className="page-title-content">
                            <img className='enefti-logo' src={eneftiLogo} alt="Enefti Logo" loading="lazy"/>
                            <h2 className="mb-4">{pageTitle}</h2>
                        </div>
                        <div className="page-info-content">
                            <p>{pageDescription}</p>
                        </div>
                    </div>
                    <img src={wordpressImg} alt="Hosting Wordpress" loading="lazy"/>
                </div>
            </div>
        )
    }

}

export default EneftiBannerArea
